// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: "http://173.212.231.241:4070/api",
  API_URL: "https://api.veracruzmunicipio.gob.mx/api",
  // API_URL: "http://173.212.231.241:4070/api",
  // API_URL: "https://api.veracruzmunicipio.gob.mx/api",
  API_KEY: 'AIzaSyA1DmnuMIcyPn5QvFazsqiqh2qGts_B9Qo',
};
